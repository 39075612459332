<template>
  <TitleBar>Site Analytics</TitleBar>
  <iframe
    plausible-embed
    src="https://plausible.io/share/mycaoffer.com?auth=tqi9EHpx3GLJz-gABSC2q&embed=true&theme=light"
    scrolling="no"
    frameborder="0"
    loading="lazy"
    style="width: 1px; min-width: 100%; height: 1600px"
  ></iframe>
  <div style="font-size: 14px; padding-bottom: 14px">
    Stats powered by
    <a
      target="_blank"
      style="color: #4f46e5; text-decoration: underline"
      href="https://plausible.io"
      >Plausible Analytics</a
    >
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import { useAppStore } from "@/stores/app";
//import COMailing from "@/models/CampaignOverviewMailing";
//import Responder from "@/models/Responder";
export default {
  components: { TitleBar },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      campaignOverview: null,
      isLoading: true,
    };
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://plausible.io/js/embed.host.js");
    document.head.appendChild(externalScript);
  },
};
</script>
